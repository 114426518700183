@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    a.active {
        @apply text-lightgreen;
    }
    .custom-btn {
        @apply border py-3 px-6 rounded;
    }

    .input-row {
        @apply mb-4;
    }

    .input-row input,
    .input-row textarea,
    .input-wrap input,
    .input-wrap textarea {
        @apply w-full border p-4 rounded-md;
    }

    .input-row select {
        @apply w-full border p-4 rounded-md bg-transparent;
    }

    .input-row input:focus,
    .input-row textarea:focus,
    .input-wrap input:focus,
    .input-wrap textarea:focus {
        @apply outline-none shadow-md;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    color: #0e1338;
    font-weight: 500;
    scroll-behavior: smooth;
    scroll-padding: 70px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.text-underline {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.text-underline::after {
    content: "";
    width: 102%;
    height: 50%;
    background: #8ad307;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;
}

.text-underline-50 {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.text-underline-50::after {
    content: "";
    width: 52%;
    height: 50%;
    background: #8ad307;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;
}

.ql-editor {
    min-height: 300px;
}

.list-style-disc > li {
    position: relative;
}

.list-style-disc > li::before {
    content: "•";
    position: absolute;
    left: -0.5rem;
    font-size: 2em;
    top: 50%;
    transform: translate(-100%, -55%);
}
