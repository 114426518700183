.loaderWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.loaderWrapper.withBg {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
}

.loader {
    position: relative;
    width: 50px;
    height: 50px;
}

.loader:before,
.loader:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    inset: 0;
    box-shadow: 0 0 10px 2px rgba(14, 19, 56, 0.5) inset;
}
.loader:after {
    box-shadow: 0 3px 0 #8ec73f inset;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
